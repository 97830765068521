import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import Breadcrumbs from '@components/seo/Breadcrumbs'
import ElementImage from '@components/global/element/Image'
import * as cx from './RealEstateBanner.module.scss'

const RealEstateBanner = ({
  wrapperClassName,
  hasDarkText,
  breadcrumbs,
  bg,
  gradient,
  img,
  heading,
  subheading,
  headingClassName = 'heading-5',
  subheadingClassName = 'subtitle-7',
}) => {
  return (
    <section
      className={clsx(wrapperClassName, gradient, [cx.wrapper], {
        [cx.darkText]: hasDarkText,
      })}
      style={bg && { backgroundImage: `url(${bg})` }}
    >
      <Breadcrumbs
        wrapperClassName={`${cx.breadcrumbs} global-breadcrumbs`}
        data={breadcrumbs}
      />

      <div className={cx.container}>
        <h2 className={cx.heading}>
          <span className={headingClassName}>{heading}</span>
          <span className={subheadingClassName}>{subheading}</span>
        </h2>

        <div className={cx.image}>
          {img && (
            <ElementImage
              src={img}
              alt={`${heading} screenshots`}
              lazy={false}
            />
          )}
        </div>
      </div>
    </section>
  )
}

RealEstateBanner.propTypes = {
  wrapperClassName: PropTypes.string,
  hasDarkText: PropTypes.bool,
  breadcrumbs: PropTypes.array,
  bg: PropTypes.string,
  gradient: PropTypes.string,
  img: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  headingClassName: PropTypes.string,
  subheadingClassName: PropTypes.string,
}

export default RealEstateBanner
