import React from 'react'

export const pageData = {
  title: '10 Best Mobile Websites',
  breadcrumbs: [
    { text: 'Home', url: '/' },
    {
      text: 'Real Estate Websites',
      url: '/real-estate-websites/',
    },
    {
      text: '10 Best Mobile Websites',
      url: '/real-estate-websites/10-best-mobile-websites/',
    },
  ],
  uri: '/real-estate-websites/10-best-mobile-websites/',
  lcf: {
    version: 'LCF03',
    model: 'Shelby',
    title: 'Talk to Our Web Marketing Specialists!',
    subtitle: 'DON’T HAVE A MOBILE RESPONSIVE WEBSITE?',
    submitLabel: 'BOOK YOUR FREE CONSULTATION',
    leadName: 'Consultation: 10 Best Mobile Websites',
    layout: 'A',
  },
}

export const bannerData = {
  heading: {
    text: '10 Best Real Estate Mobile Websites',
    className: 'heading-5',
  },
  subheading: {
    text: 'SMALL SCREENS, BIG IMPACT — IT’S POSSIBLE WITH RESPONSIVE DESIGN!',
    className: 'subtitle-7',
  },
  bgGradient: {
    className: 'bg-gradient-blue-surf',
  },
}

export const listData = {
  heading: {
    text: 'World-Class Websites For a Mobile-First World',
    className: 'heading-5',
  },
  subheading: {
    text: 'RESPONSIVE DESIGN DONE RIGHT',
    className: 'subtitle-7',
  },
  list: {
    titleClassName: 'heading-3',
    locationClassName: 'subtitle-5',
    descriptionClassName: 'default-body large',
    buttonText: 'GET PRICING & FEATURES',
    items: [
      {
        title: 'Shannon Gillette',
        location: 'Scottsdale, AZ',
        description:
          'For Shannon Gillette, homes are more than just structures—they’re gateways to an aspirational lifestyle. So with the strategic use of videos, GilletteGroupAZ.com feels less like a real estate web page and more like a glossy lifestyle brand. It also features the Gillette Group’s Home of the Day video campaign, a cutting-edge marketing listing technique that fast-tracks home sales. This is a great example of using ample white space and a sleek layout further imbue the site with effortless elegance.',
      },
      {
        title: 'The Altman Brothers',
        location: 'Los Angeles, CA',
        description:
          'As leading authorities in Los Angeles luxury real estate, the Altman Brothers embody sophistication and confidence. There is little surprise that the very same qualities describe their professional website. TheAltmanBrothers.com is the ideal platform for LA’s most prestigious multi-million dollar properties, with featured listings showcased on the home page using automated image slideshows. Brandishing a sleek layout with sharp navy and gold accents, compelling high-quality images and video, and easy-to-read content, this mobile-ready website deserves its 4th spot in RealTrends’ 2019 Best Mobile Website Rankings.',
      },
      {
        title: 'The Jills Zeder Group',
        location: 'Miami, FL',
        description:
          'The Jills Zeder Group is the #1 team in the nation with the sales volume and real estate website to back it up. The site retains its image resolution and vibrant colors on smaller mobile screens, sacrificing none of the quality or the experience of browsing on desktop. From listing information to site navigation, JillsZeder.com lets you browse with ease. With sleek and user-friendly design, their mobile site landed the #1 spot on the 2021 RealTrends Website Rankings.',
      },
      {
        title: 'Ivan Estrada',
        location: 'Beverly Hills, CA',
        description:
          'With prestigious awards and five-star reviews, Ivan Estrada is an agent at the top of his game. He also knows the importance of investing in a mobile site for clients on-the-go. IvanEstradaProperties.com provides valuable, easy-to-digest content on the mobile screen. With a clean layout and high-resolution images, the mobile site makes contacting Ivan and his team a breeze. With easy navigation and eye-catching design, the site was given a Best Mobile honorable mention on the 2021 RealTrends Website Rankings.',
      },
      {
        title: 'Ernie Carswell & Associates',
        location: 'Beverly Hills, CA',
        description:
          'Ernie Carswell & Associates caters to an elite clientele and its mobile site certainly reflects this. CarswellAndAssociates.com offers smooth navigation from start to finish. The transition from desktop to mobile doesn’t compromise image quality, layout, and design. Users can expect the same experience on mobile, whether they’re browsing or communicating with the company. This website is a goldmine of blog and video content which helped it land the 9th spot for Best Mobile on the 2020 RealTrends Website Rankings, and then again in 2021, leveling up to the top 4th spot.',
      },
      {
        title: 'Sally Forster Jones',
        location: 'Los Angeles, CA',
        description:
          'Decked in an immaculate white background with eye-catching gold accents, Sally Forster Jones’s website epitomizes elegance in simplicity. This website boasts a straightforward and mobile-friendly design that keeps the most important features front and center. Curious about the Westside LA lifestyle? Let the featured video reel take you on a journey through the region’s most exquisite living spaces. Looking for the finest homes for sale? Find the latest featured listings just below the fold. Whatever your real estate goals, you can achieve them faster with SallyForsterJones.com as your starting point.',
      },
      {
        title: 'Josh Flagg',
        location: 'Los Angeles, CA',
        description:
          'Josh Flagg is a trusted agent with over $2 billion in total sales volume. His mobile site reflects this prestige with superior architecture and effective navigation. The mobile site displays well on smaller screens with high-quality images and a user-friendly layout. Users can switch from one device to another with remarkable ease while searching for properties or checking out Josh’s credentials. With crisp images and a streamlined user experience, the site received a Best Mobile honorable mention on 2021 RealTrends Website Rankings.',
      },
      {
        title: 'Chad Carroll',
        location: 'Miami, FL',
        description:
          'South Florida’s most aspirational homes for sale instantly take the spotlight when you fire up TheChadCarrollGroup.com on your mobile device. With this deliberate design choice, Chad Carroll and his team send a clear and resounding message: With us, you are always one tap away from the home of your dreams. Backing up this bold claim are useful tools and features like quick search fields and curated listings pages—all presented with a refreshing water-inspired color scheme that evokes South Florida’s irresistible oceanfront vibe.',
      },
      {
        title: 'The Noël Team',
        location: 'Santa Monica, CA',
        description:
          'Ever wanted to hold paradise in the palm of your hand? Check out The Noel Team’s breathtaking website from your phone. Ranked sixth among RealTrends’ 10 Best Mobile websites of 2019, TheNoelTeam.net draws you in with crisp, high-definition videos of the vibrant Pacific Palisades lifestyle at the top of the home page. Scroll further down and be mesmerized by animation effects that introduce the site’s various features. Each new page element inspires action as it slides into view, inviting visitors to<br/> explore featured properties, read relevant<br/> real estate resources, and more. Now<br/> that’s smart design.',
      },
      {
        title: 'California Life Properties',
        location: 'Victorville, CA',
        description:
          'Guests are swiftly invited to explore Southern California and its vast real estate market as soon as CaliforniaLifeProperties.com’s mobile version is fully loaded. A video feature, followed by a customizable home search tool, lets people know California Life Properties means business. The crowning feature of this website is the array of location-based search functionality, where homebuyers can browse through California counties, then search further by specific neighborhood, and finally review additional criteria for price and property type.',
      },
    ],
  },
}

export const closingData = {
  heading: {
    text: 'Websites That Look Amazing',
    className: 'heading-2',
  },
  subheading: {
    text: 'EVEN ON THE GO!',
    className: 'subtitle-7',
  },
  items: [
    {
      title: 'Responsive design lets you adapt to a world gone mobile.',
      description: [
        'Desktop computers and mobile devices couldn’t be more different. But that doesn’t mean you have to develop separate websites for them. ',
        "That’s because responsive design allows website elements to resize themselves to suit whatever screen they're being viewed on. The result? Optimal browsing experience – on a laptop, a tablet, or a smartphone!",
      ],
      buttonText: 'TELL ME MORE',
      imgAlt: 'The Jills Zeder Group website screenshots on phone',
    },
    {
      title: 'It’s not magic — but it is coding wizardry!',
      description: [
        'Auto-resized images. Navigation bars tucked into a collapsible menu. Strategically placed navigation buttons.',
        'These are just some of the ways that responsive design keeps your website looking and functioning perfectly no matter the device.',
        'And the best part? Mobile responsive design is baked into each of our website packages.',
      ],
      buttonText: 'SEE OUR WEBSITE PACKAGES',
      imgAlt: 'Ivan Estrada website screenshots on phone',
    },
  ],
}
