import { useStaticQuery, graphql } from 'gatsby'

const useBest10MobileMetadata = () => {
  const query = useStaticQuery(graphql`
    query best10MobileMetadata {
      bannerImg: file(relativePath: { eq: "best-10-mobile/banner/img.png" }) {
        id
        ...Best10MobileImageOptimize
      }

      listBG: file(relativePath: { eq: "best-10-mobile/list/bg.png" }) {
        id
        ...Best10MobileImageOptimize
      }
      listBGPhone: file(
        relativePath: { eq: "best-10-mobile/list/bg-phone.jpg" }
      ) {
        id
        ...Best10MobileImageOptimize
      }
      listBGPhoneAlt: file(
        relativePath: { eq: "best-10-mobile/list/bg-phone-alt.jpg" }
      ) {
        id
        ...Best10MobileImageOptimize
      }
      listShannonTablet1: file(
        relativePath: { eq: "best-10-mobile/list/shannon-tablet-1.jpg" }
      ) {
        id
        ...Best10MobileImageOptimize
      }
      listShannonTablet2: file(
        relativePath: { eq: "best-10-mobile/list/shannon-tablet-2.jpg" }
      ) {
        id
        ...Best10MobileImageOptimize
      }
      listShannonPhone1: file(
        relativePath: { eq: "best-10-mobile/list/shannon-mobile-1.jpg" }
      ) {
        id
        ...Best10MobileImageOptimize
      }
      listShannonPhone2: file(
        relativePath: { eq: "best-10-mobile/list/shannon-mobile-2.jpg" }
      ) {
        id
        ...Best10MobileImageOptimize
      }
      listAltmanTablet1: file(
        relativePath: { eq: "best-10-mobile/list/altman-tablet-1.jpg" }
      ) {
        id
        ...Best10MobileImageOptimize
      }
      listAltmanTablet2: file(
        relativePath: { eq: "best-10-mobile/list/altman-tablet-2.jpg" }
      ) {
        id
        ...Best10MobileImageOptimize
      }
      listAltmanPhone1: file(
        relativePath: { eq: "best-10-mobile/list/altman-mobile-1.jpg" }
      ) {
        id
        ...Best10MobileImageOptimize
      }
      listAltmanPhone2: file(
        relativePath: { eq: "best-10-mobile/list/altman-mobile-2.jpg" }
      ) {
        id
        ...Best10MobileImageOptimize
      }
      listJillsTablet1: file(
        relativePath: { eq: "best-10-mobile/list/jills-tablet-1.jpg" }
      ) {
        id
        ...Best10MobileImageOptimize
      }
      listJillsTablet2: file(
        relativePath: { eq: "best-10-mobile/list/jills-tablet-2.jpg" }
      ) {
        id
        ...Best10MobileImageOptimize
      }
      listJillsPhone1: file(
        relativePath: { eq: "best-10-mobile/list/jills-mobile-1.jpg" }
      ) {
        id
        ...Best10MobileImageOptimize
      }
      listJillsPhone2: file(
        relativePath: { eq: "best-10-mobile/list/jills-mobile-2.jpg" }
      ) {
        id
        ...Best10MobileImageOptimize
      }
      listIvanTablet1: file(
        relativePath: { eq: "best-10-mobile/list/ivan-tablet-1.jpg" }
      ) {
        id
        ...Best10MobileImageOptimize
      }
      listIvanTablet2: file(
        relativePath: { eq: "best-10-mobile/list/ivan-tablet-2.jpg" }
      ) {
        id
        ...Best10MobileImageOptimize
      }
      listIvanPhone1: file(
        relativePath: { eq: "best-10-mobile/list/ivan-mobile-1.jpg" }
      ) {
        id
        ...Best10MobileImageOptimize
      }
      listIvanPhone2: file(
        relativePath: { eq: "best-10-mobile/list/ivan-mobile-2.jpg" }
      ) {
        id
        ...Best10MobileImageOptimize
      }
      listErnieTablet1: file(
        relativePath: { eq: "best-10-mobile/list/ernie-tablet-1.jpg" }
      ) {
        id
        ...Best10MobileImageOptimize
      }
      listErnieTablet2: file(
        relativePath: { eq: "best-10-mobile/list/ernie-tablet-2.jpg" }
      ) {
        id
        ...Best10MobileImageOptimize
      }
      listErniePhone1: file(
        relativePath: { eq: "best-10-mobile/list/ernie-mobile-1.jpg" }
      ) {
        id
        ...Best10MobileImageOptimize
      }
      listErniePhone2: file(
        relativePath: { eq: "best-10-mobile/list/ernie-mobile-2.jpg" }
      ) {
        id
        ...Best10MobileImageOptimize
      }
      listSallyTablet1: file(
        relativePath: { eq: "best-10-mobile/list/sally-tablet-1.jpg" }
      ) {
        id
        ...Best10MobileImageOptimize
      }
      listSallyTablet2: file(
        relativePath: { eq: "best-10-mobile/list/sally-tablet-2.jpg" }
      ) {
        id
        ...Best10MobileImageOptimize
      }
      listSallyPhone1: file(
        relativePath: { eq: "best-10-mobile/list/sally-mobile-1.jpg" }
      ) {
        id
        ...Best10MobileImageOptimize
      }
      listSallyPhone2: file(
        relativePath: { eq: "best-10-mobile/list/sally-mobile-2.jpg" }
      ) {
        id
        ...Best10MobileImageOptimize
      }
      listJoshTablet1: file(
        relativePath: { eq: "best-10-mobile/list/josh-tablet-1.jpg" }
      ) {
        id
        ...Best10MobileImageOptimize
      }
      listJoshTablet2: file(
        relativePath: { eq: "best-10-mobile/list/josh-tablet-2.jpg" }
      ) {
        id
        ...Best10MobileImageOptimize
      }
      listJoshPhone1: file(
        relativePath: { eq: "best-10-mobile/list/josh-mobile-1.jpg" }
      ) {
        id
        ...Best10MobileImageOptimize
      }
      listJoshPhone2: file(
        relativePath: { eq: "best-10-mobile/list/josh-mobile-2.jpg" }
      ) {
        id
        ...Best10MobileImageOptimize
      }
      listChadTablet1: file(
        relativePath: { eq: "best-10-mobile/list/chad-tablet-1.jpg" }
      ) {
        id
        ...Best10MobileImageOptimize
      }
      listChadTablet2: file(
        relativePath: { eq: "best-10-mobile/list/chad-tablet-2.jpg" }
      ) {
        id
        ...Best10MobileImageOptimize
      }
      listChadPhone1: file(
        relativePath: { eq: "best-10-mobile/list/chad-mobile-1.jpg" }
      ) {
        id
        ...Best10MobileImageOptimize
      }
      listChadPhone2: file(
        relativePath: { eq: "best-10-mobile/list/chad-mobile-2.jpg" }
      ) {
        id
        ...Best10MobileImageOptimize
      }
      listNoelTablet1: file(
        relativePath: { eq: "best-10-mobile/list/noel-tablet-1.jpg" }
      ) {
        id
        ...Best10MobileImageOptimize
      }
      listNoelTablet2: file(
        relativePath: { eq: "best-10-mobile/list/noel-tablet-2.jpg" }
      ) {
        id
        ...Best10MobileImageOptimize
      }
      listNoelPhone1: file(
        relativePath: { eq: "best-10-mobile/list/noel-mobile-1.jpg" }
      ) {
        id
        ...Best10MobileImageOptimize
      }
      listNoelPhone2: file(
        relativePath: { eq: "best-10-mobile/list/noel-mobile-2.jpg" }
      ) {
        id
        ...Best10MobileImageOptimize
      }
      listCaliforniaTablet1: file(
        relativePath: { eq: "best-10-mobile/list/california-tablet-1.jpg" }
      ) {
        id
        ...Best10MobileImageOptimize
      }
      listCaliforniaTablet2: file(
        relativePath: { eq: "best-10-mobile/list/california-tablet-2.jpg" }
      ) {
        id
        ...Best10MobileImageOptimize
      }
      listCaliforniaPhone1: file(
        relativePath: { eq: "best-10-mobile/list/california-mobile-1.jpg" }
      ) {
        id
        ...Best10MobileImageOptimize
      }
      listCaliforniaPhone2: file(
        relativePath: { eq: "best-10-mobile/list/california-mobile-2.jpg" }
      ) {
        id
        ...Best10MobileImageOptimize
      }

      closingImg1: file(
        relativePath: { eq: "best-10-mobile/closing/img-1.png" }
      ) {
        id
        ...Best10MobileImageOptimize
      }
      closingImg2: file(
        relativePath: { eq: "best-10-mobile/closing/img-2.png" }
      ) {
        id
        ...Best10MobileImageOptimize
      }

      lcfBGPhone: file(
        relativePath: { eq: "best-10-mobile/others/lcf-bg-phone.jpg" }
      ) {
        id
        ...Best10MobileImageOptimize
      }
    }

    fragment Best10MobileImageOptimize on File {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, quality: 90, formats: [AUTO])
      }
    }
  `)

  return query
}

export default useBest10MobileMetadata
