import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide'

import ElementImage from '@components/global/element/Image'
import tabletPlaceholder from '/content/assets/best-10-mobile/list/tablet-placeholder.png'
import phonePlaceholder from '/content/assets/best-10-mobile/list/phone-placeholder.png'
import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'
import * as cx from './TabletPhoneSplider.module.scss'

const TabletSplider = React.forwardRef(
  (
    { images, alt, placeholderWidth, placeholderHeight, width, height },
    ref
  ) => {
    return (
      <div className={cx.tablet}>
        <canvas
          width={placeholderWidth}
          height={placeholderHeight}
          style={{
            backgroundImage: `url(${tabletPlaceholder})`,
          }}
          className="global-devices-tablet-placeholder"
        />
        <Splide
          ref={ref}
          hasTrack={false}
          tag="div"
          options={{
            type: 'loop',
            rewind: false,
            arrows: false,
            pagination: true,
            perPage: 1,
            lazyLoad: 'sequential',
            autoplay: true,
            interval: 3000,
            speed: 800,
          }}
          className={cx.tabletCarousel}
        >
          <SplideTrack>
            {images.map((item, index) => {
              return (
                <SplideSlide key={index}>
                  <ElementImage
                    src={extractImage(item)}
                    width={width}
                    height={height}
                    alt={`${alt} screenshot ${index} on tablet`}
                  />
                </SplideSlide>
              )
            })}
          </SplideTrack>
        </Splide>
      </div>
    )
  }
)

const PhoneSplider = React.forwardRef(
  (
    { images, alt, placeholderWidth, placeholderHeight, width, height },
    ref
  ) => {
    return (
      <div className={cx.phone}>
        <canvas
          width={placeholderWidth}
          height={placeholderHeight}
          style={{
            backgroundImage: `url(${phonePlaceholder})`,
          }}
        />
        <Splide
          ref={ref}
          hasTrack={false}
          tag="div"
          options={{
            type: 'loop',
            rewind: false,
            arrows: false,
            pagination: false,
            perPage: 1,
            lazyLoad: 'sequential',
            autoplay: true,
            interval: 3000,
            speed: 800,
          }}
          className={cx.phoneCarousel}
        >
          <SplideTrack>
            {images.map((item, index) => {
              return (
                <SplideSlide key={index}>
                  <ElementImage
                    src={extractImage(item)}
                    width={width}
                    height={height}
                    alt={`${alt} screenshot ${index} on phone`}
                  />
                </SplideSlide>
              )
            })}
          </SplideTrack>
        </Splide>
      </div>
    )
  }
)

const TabletPhoneSplider = ({
  wrapperClassName,
  tablet,
  phone,
  alt,
  isAlternate,
  dimension = {
    tablet: {
      placeholder: { width: 700, height: 911 },
      image: { width: 700, height: 934 },
    },
    phone: {
      placeholder: { width: 300, height: 540 },
      image: { width: 300, height: 658 },
    },
  },
}) => {
  const tabletSpliderRef = useRef()
  const phoneSpliderRef = useRef()

  useEffect(() => {
    tabletSpliderRef?.current.sync(phoneSpliderRef?.current?.splide)
  }, [tabletSpliderRef, phoneSpliderRef])

  return (
    <div
      className={clsx([cx.wrapper], wrapperClassName, {
        [cx.alternate]: isAlternate,
      })}
    >
      <TabletSplider
        ref={tabletSpliderRef}
        images={tablet}
        alt={alt}
        placeholderWidth={dimension.tablet.placeholder.width}
        placeholderHeight={dimension.tablet.placeholder.height}
        width={dimension.tablet.image.width}
        height={dimension.tablet.image.height}
      />
      <PhoneSplider
        ref={phoneSpliderRef}
        images={phone}
        alt={alt}
        placeholderWidth={dimension.phone.placeholder.width}
        placeholderHeight={dimension.phone.placeholder.height}
        width={dimension.phone.image.width}
        height={dimension.phone.image.height}
      />
    </div>
  )
}

TabletPhoneSplider.propTypes = {
  wrapperClassName: PropTypes.string,
  tablet: PropTypes.array,
  phone: PropTypes.array,
  alt: PropTypes.string,
  isAlternate: PropTypes.bool,
  dimension: PropTypes.object,
}

export default TabletPhoneSplider
