import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import clsx from 'clsx'

import TabletPhoneSplider from '@components/global/devices/TabletPhoneSplider'
import ElementImage from '@components/global/element/Image'
import { Button } from '@components/global/buttons/Buttons'
import ModalForm from '@components/global/modal/Form'
import FormSignUp from '@components/global/forms/SignUp'
import { useIntersect } from '@hooks/useIntersect'
import useWindowSize from '@hooks/useWindowSize'
import SvgLocation from '@svg/location.inline'
import * as cx from './List.module.scss'

const WebsiteItem = ({
  bg,

  title,
  location,
  description,
  buttonText,
  tablet,
  phone,
  isAlternate,
  titleClassName = 'heading-3',
  locationClassName = 'subtitle-5',
  descriptionClassName = 'default-body large',
  ctaOnClick,
}) => {
  return (
    <li className={cx.item}>
      <div className={cx.deviceContainer}>
        <TabletPhoneSplider
          wrapperClassName={cx.devices}
          isAlternate={isAlternate}
          tablet={tablet}
          phone={phone}
          alt={title}
        />
      </div>
      <div className={cx.detailsContainer}>
        <ElementImage className={cx.bg} src={bg} alt="background" />
        <div className={cx.details}>
          <h5 className={titleClassName}>{title}</h5>

          <div className={cx.location}>
            <SvgLocation color="currentColor" />
            <span className={locationClassName}>{location}</span>
          </div>

          <p className={descriptionClassName}>{parse(description)}</p>
          <Button text={buttonText} onClick={ctaOnClick} />
        </div>
      </div>
    </li>
  )
}

const List = ({
  wrapperClassName,
  bg,
  bgPhone,
  bgPhoneAlt,
  heading,
  subheading,
  buttonText,
  headingClassName = 'heading-5',
  subheadingClassName = 'subtitle-7',
  items,
  imgItems = [],
  modalTitle = 'Sign Up Today For A <br>Free Consultation',
  modalDescription = 'See how Agent Image can help you grow your real estate business.',
  modalSubmit = 'Get Started Today',
  modalTemplate = 'm1',
}) => {
  const modalRef = useRef()
  const isPhone = useWindowSize().width <= 767

  return (
    <section className={clsx([cx.wrapper], wrapperClassName)}>
      <h3 className={cx.header}>
        <span className={headingClassName}>{heading}</span>
        <span className={subheadingClassName}>{subheading}</span>
      </h3>

      <ul className={cx.list}>
        {items?.map((item, key) => {
          return (
            <WebsiteItem
              key={key}
              isAlternate={key % 2 === 0}
              bg={!isPhone ? bg : key % 2 === 0 ? bgPhone : bgPhoneAlt}
              title={item.title}
              location={item?.location}
              description={item.description}
              buttonText={buttonText}
              tablet={imgItems[key].tablet}
              phone={imgItems[key].phone}
              ctaOnClick={() => modalRef.current.openModal()}
            />
          )
        })}
      </ul>

      <ModalForm
        wrapperClassName={cx.modal}
        childFunc={modalRef}
        title={modalTitle}
        description={modalDescription}
        template={modalTemplate}
      >
        <FormSignUp submitLabel={modalSubmit} />
      </ModalForm>
    </section>
  )
}

List.propTypes = {
  wrapperClassName: PropTypes.string,
  bg: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  buttonText: PropTypes.string,
  headingClassName: PropTypes.string,
  subheadingClassName: PropTypes.string,
  items: PropTypes.array,
  imgItems: PropTypes.array,
  modalTitle: PropTypes.string,
  modalDescription: PropTypes.string,
  modalSubmit: PropTypes.string,
  modalTemplate: PropTypes.string,
}

export default List
