import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { navigate } from 'gatsby'

import ElementImage from '@components/global/element/Image'
import { Button } from '@components/global/buttons/Buttons'
import ModalForm from '@components/global/modal/Form'
import FormSignUp from '@components/global/forms/SignUp'
import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'
import * as cx from './Closing.module.scss'

const Card = ({
  title,
  description,
  buttonText,
  img,
  imgAlt,
  titleClassName = 'heading-3',
  descriptionClassName = 'subtitle-5',
  ctaOnClick,
}) => {
  return (
    <div className={cx.card}>
      <div className={cx.innerContainer}>
        <div className={cx.image}>
          <ElementImage src={extractImage(img)} alt={imgAlt} />
        </div>
        <div className={cx.content}>
          <h4 className={titleClassName}>{title}</h4>

          {description?.map((description, key) => {
            return (
              <p key={key} className={descriptionClassName}>
                {description}
              </p>
            )
          })}
          <Button text={buttonText} onClick={ctaOnClick} />
        </div>
      </div>
    </div>
  )
}

const Closing = ({
  wrapperClassName,
  heading,
  subheading,
  headingClassName = 'heading-2',
  subheadingClassName = 'subtitle-7',
  items,
  imgItems,
  modalTitle = 'Sign Up Today For A <br>Free Consultation',
  modalDescription = 'See how Agent Image can help you grow your real estate business.',
  modalSubmit = 'Get Started Today',
  modalTemplate = 'm1',
}) => {
  const modalRef = useRef()
  const modalHandler = () => modalRef.current.openModal()
  const navigateHandler = () => navigate('/real-estate-websites/')

  return (
    <section className={clsx([cx.wrapper], wrapperClassName)}>
      <h3 className={cx.header}>
        <span className={headingClassName}>{heading}</span>
        <span className={subheadingClassName}>{subheading}</span>
      </h3>
      <div className={cx.container}>
        {items?.map((item, key) => {
          return (
            <Card
              key={key}
              title={item.title}
              description={item.description}
              buttonText={item.buttonText}
              img={imgItems[key]}
              imgAlt={item.imgAlt}
              ctaOnClick={key === 0 ? modalHandler : navigateHandler}
            />
          )
        })}
      </div>

      <ModalForm
        wrapperClassName={cx.modal}
        childFunc={modalRef}
        title={modalTitle}
        description={modalDescription}
        template={modalTemplate}
      >
        <FormSignUp submitLabel={modalSubmit} />
      </ModalForm>
    </section>
  )
}

Closing.propTypes = {
  wrapperClassName: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  headingClassName: PropTypes.string,
  subheadingClassName: PropTypes.string,
  items: PropTypes.array,
  imgItems: PropTypes.array,
  modalTitle: PropTypes.string,
  modalDescription: PropTypes.string,
  modalSubmit: PropTypes.string,
  modalTemplate: PropTypes.string,
}

export default Closing
