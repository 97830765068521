import React from 'react'

import LayoutInnerPage from '@src/layouts/InnerPage'
import Seo from '@components/seo/Index'
import RealEstateBanner from '@components/global/banner/RealEstateBanner'
import { List, Closing } from '@components/pages/best-10-mobile'
import LeadContactForm from '@components/global/forms/LeadContactForm/Index'

import {
  pageData as page,
  bannerData,
  listData,
  closingData,
} from '@src/data/Best10Mobile'
import useBest10MobileMetadata from '@hooks/best-10-mobile-metadata'
import useMedia, { media } from '@hooks/useMedia'
import useWindowSize from '@hooks/useWindowSize'
import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'
import * as cx from './10BestMobile.module.scss'

const Best10MobileWebsites = () => {
  const {
    bannerImg,
    listBG,
    listBGPhone,
    listBGPhoneAlt,
    listShannonTablet1,
    listShannonTablet2,
    listShannonPhone1,
    listShannonPhone2,
    listAltmanTablet1,
    listAltmanTablet2,
    listAltmanPhone1,
    listAltmanPhone2,
    listJillsTablet1,
    listJillsTablet2,
    listJillsPhone1,
    listJillsPhone2,
    listIvanTablet1,
    listIvanTablet2,
    listIvanPhone1,
    listIvanPhone2,
    listErnieTablet1,
    listErnieTablet2,
    listErniePhone1,
    listErniePhone2,
    listSallyTablet1,
    listSallyTablet2,
    listSallyPhone1,
    listSallyPhone2,
    listJoshTablet1,
    listJoshTablet2,
    listJoshPhone1,
    listJoshPhone2,
    listChadTablet1,
    listChadTablet2,
    listChadPhone1,
    listChadPhone2,
    listNoelTablet1,
    listNoelTablet2,
    listNoelPhone1,
    listNoelPhone2,
    listCaliforniaTablet1,
    listCaliforniaTablet2,
    listCaliforniaPhone1,
    listCaliforniaPhone2,
    closingImg1,
    closingImg2,
    lcfBGPhone,
  } = useBest10MobileMetadata()

  const imgListData = [
    {
      tablet: [listShannonTablet1, listShannonTablet2],
      phone: [listShannonPhone1, listShannonPhone2],
    },
    {
      tablet: [listAltmanTablet1, listAltmanTablet2],
      phone: [listAltmanPhone1, listAltmanPhone2],
    },
    {
      tablet: [listJillsTablet1, listJillsTablet2],
      phone: [listJillsPhone1, listJillsPhone2],
    },
    {
      tablet: [listIvanTablet1, listIvanTablet2],
      phone: [listIvanPhone1, listIvanPhone2],
    },
    {
      tablet: [listErnieTablet1, listErnieTablet2],
      phone: [listErniePhone1, listErniePhone2],
    },
    {
      tablet: [listSallyTablet1, listSallyTablet2],
      phone: [listSallyPhone1, listSallyPhone2],
    },
    {
      tablet: [listJoshTablet1, listJoshTablet2],
      phone: [listJoshPhone1, listJoshPhone2],
    },
    {
      tablet: [listChadTablet1, listChadTablet2],
      phone: [listChadPhone1, listChadPhone2],
    },
    {
      tablet: [listNoelTablet1, listNoelTablet2],
      phone: [listNoelPhone1, listNoelPhone2],
    },
    {
      tablet: [listCaliforniaTablet1, listCaliforniaTablet2],
      phone: [listCaliforniaPhone1, listCaliforniaPhone2],
    },
  ]
  const closingImgData = [closingImg1, closingImg2]
  const isPhone = useWindowSize().width <= 767

  return (
    <LayoutInnerPage
      hasWhiteTransparentNav
      noFloatingShareButtons
      wrapperClassName={cx.wrapper}
    >
      <Seo title={page.title} uri={page.uri} />
      <RealEstateBanner
        breadcrumbs={page.breadcrumbs}
        wrapperClassName={cx.banner}
        heading={bannerData.heading.text}
        subheading={bannerData.subheading.text}
        gradient={bannerData.bgGradient.className}
        img={extractImage(bannerImg)}
      />
      <List
        wrapperClassName={cx.list}
        bg={extractImage(listBG)}
        bgPhone={extractImage(listBGPhone)}
        bgPhoneAlt={extractImage(listBGPhoneAlt)}
        heading={listData.heading.text}
        subheading={listData.subheading.text}
        buttonText={listData.list.buttonText}
        items={listData.list.items}
        imgItems={imgListData}
      />
      <Closing
        heading={closingData.heading.text}
        subheading={closingData.subheading.text}
        items={closingData.items}
        imgItems={closingImgData}
      />
      <LeadContactForm
        version={page.lcf.version}
        background={isPhone ? lcfBGPhone : ''}
        modelOrBackground={page.lcf.model}
        title={page.lcf.title}
        subtitle={page.lcf.subtitle}
        submitLabel={page.lcf.submitLabel}
        leadName={page.lcf.leadName}
        layout={page.lcf.layout}
      />
    </LayoutInnerPage>
  )
}

export default Best10MobileWebsites
