// extracted by mini-css-extract-plugin
export var bg = "List-module--bg--d875b";
export var details = "List-module--details--ccb65";
export var detailsContainer = "List-module--details-container--38a8c";
export var deviceContainer = "List-module--device-container--fb9dd";
export var devices = "List-module--devices--d68d0";
export var header = "List-module--header--e64fc";
export var item = "List-module--item--d779b";
export var itemWrapper = "List-module--item-wrapper--644df";
export var list = "List-module--list--872c7";
export var location = "List-module--location--2cfed";
export var modal = "List-module--modal--4a288";
export var wrapper = "List-module--wrapper--303dc";